import React from "react";

 interface IYoutubeEmbed{
    embedId:string;
    width?:string;
    height?:string;
 }

const YoutubeEmbed = ({ embedId , width = "410px" , height = "250px"}:IYoutubeEmbed) => (
  <div className="video-responsive">
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?autoplay=1&mute=1`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{width: width, height: height}}
      title="Embedded youtube"
    />
  </div>
);


export default YoutubeEmbed;