import React from "react";


const Logo = ( ) => {
  return (
    <>
    <div className="mb-1  font-noah-black-700 left-div font-size-20">
        <img
              src="/konzortiaLogoHeader.svg"
              alt="user-profile"
              className="mx-auto lg:mx-0 logo-footer"
            />       
    </div>
        <span className="inline-block mt-1 lg:mt-0 font-primary color-black font-size-16 !text-left">©2024 All rights reserved</span>
</>
  );
};

export default Logo;