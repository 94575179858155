import React from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { ContactUs } from '../Home/ContactUs';

export const Description = () => {
  return (
    <>
      <div className='px-4 py-12 mx-auto container w-12/12 bg-white '>
      <div className='flex flex-col lg:flex-row'>
        <div className="lg:w-8/12 w-12/12">
          <div className="flex flex-col">
            <div className="flex flex-col center-div lg:flex-row">
              <div className="px-2"><img src="/contact1.svg" alt='konzortia' className="px-4 py-6"/></div>
              <div className="px-2"><img src="/contact2.svg" alt='konzortia' className="px-4 py-6"/></div>
            </div>
            <div className="flex center-div flex-col lg:flex-row">
              <div className="px-2"><img src="/contact3.svg" alt='konzortia' className="px-4 py-6"/></div>
              <div className="px-2"><img src="/contact4.svg" alt='konzortia' className="px-4 py-6"/></div>
            </div>
            <div className="flex flex-col center-div  lg:flex-row">
              <div className="px-2"><img src="/contact5.svg" alt='konzortia' className="px-4 py-6"/></div>
              <div className="px-2"><img src="/contact6.svg" alt='konzortia' className="px-4 py-6"/></div>
            </div>
          </div>
        </div>

          <div className='py-12 w-12/12 lg:w-4/12'>
            <div className='flex flex-col h-100'>
              <ContactUs />
              <div className='flex  py-10 px-2 w-12/12 '>
                <div className='social-media-card w-100 h-100'>
                  <div className='flex flex-row w-12/12 h-100 center-div'>
                    <span className='font-secondary-italic text-white font-size-24'>
                      Follow us!
                    </span>
                    <div className='px-2 color-white'>
                      <AiOutlineTwitter size={20} />
                    </div>
                    <div className='px-2 color-white'>
                      <AiFillFacebook size={20} />
                    </div>
                    <div className='px-2 color-white'>
                      <AiFillLinkedin size={20} />
                    </div>
                    <div className='px-2 color-white'>
                      <AiOutlineInstagram size={20} />
                    </div>
                  </div>
                </div>
              </div>
           
            </div>
          </div>
      </div>
      </div>

    </>
  );
};
