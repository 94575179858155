import React , {useState} from 'react';
import { toast } from "react-toastify";
export const Description = () => {
  const [loader, setLoader] = useState(false)
 
const [form_, set_form] = useState({
  name: "",
  email: "",
  phone: "",
  type:"invest_kc"
});

const handleChangeinput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;
  set_form({ ...form_, [name]: value });
};

const showSuccessNotification = (message: any) => {
  toast.success(message);
};

const showErrorNotification = (error: any) => {
  toast.error(error);
};
const handleSubmitForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
  e.preventDefault(); // Prevent form submission
  setLoader(true);

  try {
    fetch('https://konzortiacapital.com/save.php', {
      method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(form_),
  })
    .then(response => response.json())
    .then(data => {
      if (data.message == 'Data received successfully') {
        setLoader(false);

        showSuccessNotification('Successfully submitted, We will contact you soon.');
        set_form({
          name: "",
          email: "",
          phone: "",
          type:"invest_kc", 
        });
      } else {
        showErrorNotification("An error Occured, Try again later!");
        set_form({
          name: "",
          email: "",
          phone: "",
          type:"invest_kc", 
        });
        setLoader(false);
      }

    });
  } catch (error) {
      console.error('Error submitting form:',error);
      showErrorNotification("An error Occured, Try again later!");
      setLoader(false);
  }
};
  return (
    <>
      <div className="w-12/12 py-12 px-12 flex flex-col " style={{background: "linear-gradient(108.78deg, rgb(28, 72, 142) 2.38%, rgb(38, 152, 198) 93.59%)"}}>
        <div className=" place-content-center items-center justify-center w-12/12 flex py-12 left-div">
          <div className="flex flex-col">
            <span className=" text-center flex font-tertiary font-size-34 text-white font-bold py-4">Thank you for considering Konzortia Capital!</span> 
            <div className="flex font-thin flex-row text-center justify-center place-content-center items-center">
              <span className="font-primary-thin justify-center place-content-center items-center text-center font-size-22 text-white font-thin " style={{fontWeight:"300"}}>We look forward to meeting with you.
                <p className="mt-8 justify-center font-thin place-content-center items-center text-center flex">Please review this video to learn more…</p>
                <p className="text-center lg:flex">And please schedule a zoom meeting with the Founder here on </p>
                <span className="lg:px-1 text-light-blue cursor-pointer font-bold ">Calendly.</span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-6/12  flex flex-col center-div">
            <div className="relative w-98 h-58">
              <img src="/computer.svg" alt="Computer" className="w-full h-full"/>
              <div className="absolute inset-0 flex items-center pb-4 justify-center">
                  <video controls width="600" height="350">
                    <source src="/careers.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
              </div>
            </div>
            <div className="py-4 pt-24 lg:pt-0 lg:py-0 ">
              <button type="button" className="btn-primary lg:w-56 px-12  lg:px-0 w-full text-white font-primary uppercase font-bold"  onClick={()=>(window.location.href='https://calendly.com/walter-gomez-kc/priority-investor-zoom-meeting-with-the-found-clone-1')}>Schedule meeting</button>
            </div>
          </div>
          <div className="lg:w-6/12  flex flex-col ">
            <span className="font-tertiary text-white font-size-34">Contact Us!</span>
            <div className="flex center-div"><img src="/line.svg" alt='line' className="w- flex center-div"/></div>
            <div className="flex center-div ">
              <div className="w-96 flex flex-col">
                <div className="pt-2 w-full  flex justify-center items-center place-items-center place-content-center content-center w-12/12 px-2 pb-2"> 
                  <input
                    type='text'
                    name='name'
                    placeholder='Full Name'
                    onChange={handleChangeinput}
                    className='form-control-kc text-white w-full font-primary font-size-12 '
                  />
                </div>
                <div className="flex flex row w-12/12">
                  <div className="w-full w-6/12 px-2 pb-2"> 
                    <input
                      type='text'
                      name='email'
                      placeholder='Email'
                      onChange={handleChangeinput}
                      className='form-control-kc text-white w-full font-primary font-size-12 '  
                    />
                  </div>
                  <div className="w-full w-6/12 px-2 pb-2"> 
                    <input
                      type='text'
                      name='phone'
                      placeholder='Phone'
                      onChange={handleChangeinput}
                      className='form-control-kc text-white w-full font-primary font-size-12 '  
                      />
                  </div>
                </div>
                <div className="flex pt-4 pl-1 flex-row">
                  <div className="form-check form-check-info font-size-10">
                    <input
                    type='checkbox'
                    name='agreement'

                    className='mt-1 font-label form-check-input'  
                    />
                  </div>
                  <div className=" font-primary-thin text-white font-size-12 ml-1">By signing up, you agree to our 
                  <span> 
                    <a href="/privacy-policy" className="font-primary text-dark-blue pointer">Privacy Policy</a>, 
                    <a target="_blank" className="font-primary text-dark-blue  pointer">Cookies Policy </a>and 
                    <a target="_blank" className="font-primary text-dark-blue  pointer">Terms of Use. </a>
                    You also agree to receive Konzortia Hub’s emails and messages about our products and services.
                  </span>
                </div>
              </div>
              <div className="flex center-div content-center justify-center">
                <button className="btn-dark-blue bg-dark-blue mt-4 lg:w-36 px-12  lg:px-0 w-full text-white font-primary uppercase font-bold flex justify-center content-center" onClick={handleSubmitForm} disabled={loader} type="button">   { loader ? <div className="mt-2 spinner" ></div> : <span className="mt-1">Send</span>}</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
