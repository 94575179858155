import React from 'react';
import { Helmet } from 'react-helmet';

export const Tags = () => { 
  return (
    <div>
      <Helmet>
        <title>Invest with Konzortia Capital: Your Gateway to Exclusive Private Market Opportunities</title>
        <meta name="description" content="Invest with Konzortia Capital and gain access to exclusive private market opportunities. Leverage our advanced platform for deal sourcing, capital raising, and maximizing investment growth." />
      </Helmet>
    </div>
  );
};
