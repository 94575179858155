import React from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { ContactUs } from './ContactUs';

export const Description = () => {
  return (
    <>
      <div className='px-4 py-12 mx-auto container w-12/12 bg-white '>
        <img src='/separatorHero.svg'  alt='konzortia'/>
        <div className='flex flex-col md:flex-row lg:flex-row '>
          <div className='lg:w-8/12 w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>
            <span className='font-primary font-size-22 text-black '>
              Konzortia Capital is a consortium of platforms providing solutions
              to shift paradigms and resolve inefficiencies in Private Capital
              Markets for Venture Capital, Private Equity and Companies raising
              capital.
            </span>
            <span className='font-primary font-size-22 text-black font-bold py-4'>
              MATCH – SOURCE - EXIT
            </span>
            <span className='font-primary font-size-22 text-black py-4'>
              Our platform <span className='font-bold '>MATCH</span> investors…{' '}
              <span className='font-bold '>SOURCE</span> deals and provide
              liquidity through blockchain{' '}
              <span className='font-bold '>EXIT</span> settlement solutions. For
              investors, Source deals increasing your flow, and for Founders
              find the right impact investors.
            </span>
            <div className='h-full pt-12'>
              <img src='/industryGaps.svg' alt='konzortia' />
            </div>
          </div>
          <div className='py-12 w-12/12 lg:w-4/12'>
            <div className='flex flex-col h-100'>
              <ContactUs />
              <div className='flex  py-10 px-2 w-12/12 '>
                <div className='social-media-card w-100 h-100'>
                  <div className='flex flex-row w-12/12 h-100 center-div'>
                    <span className='font-secondary-italic text-white font-size-24'>
                      Follow us!
                    </span>
                    <div className='px-2 color-white'>
                      <AiOutlineTwitter size={20} />
                    </div>
                    <div className='px-2 color-white'>
                      <AiFillFacebook size={20} />
                    </div>
                    <div className='px-2 color-white'>
                      <AiFillLinkedin size={20} />
                    </div>
                    <div className='px-2 color-white'>
                      <AiOutlineInstagram size={20} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full px-2 flex center-div'>
              <img src='/KonzortiaCapitalCard.svg' alt='konzortia'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
