import React from 'react';
import { Helmet } from 'react-helmet';

export const Tags = () => { 
  return (
    <div>
      <Helmet>
        <title>Konzortia Capital: Empowering Private Market Investments with Innovation</title>
        <meta name="description" content="Konzortia Capital is a pioneering holding company and FinTech consortium dedicated to transforming the Private Capital Markets." />
      </Helmet>
    </div>
  );
};
