import React from 'react';
import { Helmet } from 'react-helmet';

export const Tags = () => { 
  return (
    <div>
      <Helmet>
        <title>Contact Konzortia Capital: Connect with Our Private Market Experts</title>
        <meta name="description" content="Contact Konzortia Capital to learn more about our private market investment solutions. Connect with our team for inquiries about deal sourcing, capital raising, and partnership opportunities." />
      </Helmet>
    </div>
  );
};
