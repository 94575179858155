import React from 'react';
import { Helmet } from 'react-helmet';

export const Tags = () => { 
  return (
    <div>
      <Helmet>
        <title>Investment Opportunities with Konzortia Capital: Unlocking Growth in Private Markets</title>
        <meta name="description" content="Explore investment opportunities with Konzortia Capital. Our portfolio of FinTech companies offers access to exclusive private market investments, innovative deal sourcing, and capital raising solutions tailored to investors' needs." />
      </Helmet>
    </div>
  );
};
