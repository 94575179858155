import React from 'react';
import 'react-phone-number-input/style.css';
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';

const customStyles = {
  control: () => ({
    minWidth: '100%',
    display: 'flex',
    height: '40px',
    borderRadius: '6px',
    background: '#F8F8F8',
    borderTop: 'transparent',
    borderLeft: 'transparent',
    borderRight: 'transparent',
    fontSize: '12px',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',

    //   padding: "6px 3px 3px 6px",
    color: '#BDBDBD',
    alignItems: 'left',
    justifyContent: 'left',
  }),
  option: () => ({
    cursor: 'pointer',
    fontSize: '10px',
    marginBottom: '2px',
    padding: '10px',
    color: '#BDBDBD',
    '&:hover': {
      background: '#2698C6',
      color: 'black',
    },
  }),
};
export const ContactCard = () => {
 
  
  return (
    
    <div className='px-4 py-4'>
      <div className='bg-gray rounded center-div py-4 '>

        <div className='flex flex-col'>
        <div className='px-4  font-primary color-primary font-size-20'>
        Contact Us
                    </div>
        <div className='px-12 flex flex-col py-4 font-primary font-size-12'>
      <span className='font-bold color-black'>Konzortia Capital</span>  
<span className='font-thin'>For more information on our company or investor inquiries, please contact:    </span>        
</div>
<div className='flex flex-row center-div'>
<div className='color-primary px-2'>
<AiOutlineMail/>
</div>
<span className='font-primary color-black font-size-12'>
capital@konzortia.capital
</span>
</div>
<div className='flex flex-row center-div'>
<div className='color-primary px-2'>
<BsTelephone/>
</div>
<span className='font-primary color-black font-size-12'>
+1 (833) 930-2161
</span>
</div>
            </div>
    
        
      </div>
    </div>
  );
};
