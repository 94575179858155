import React from 'react';


export const Description = () => {
  return (
    <>
      <div className="flex place-content-center px-12 container mx-auto py-12 items-center justify-center flex-col">
        <div className="py-4">
          <span className="font-primary font-bold font-size-32 text-xl text-black">Privacy Policy</span>
        </div>
        <div className="flex place-content-left content-left text-justify items-left justify-left">
          <span className="font-primary">
            <p className="py-4">
              The privacy of Konzortia Hub users and their information is extremely important to Konzortia Hub and we take this matter very seriously. 
              Any Konzortia Hub services are provided through Konzortia Hub platform (the “Platform”).
            </p>
            <p className="py-4">
              All user(s) of Konzortia Hub’s services (the “User(s)) (services provided through the following https:fintechinvestment.konzortia.capital/ the “Site”) are covered by this Privacy Policy (the “Privacy Policy”).Konzortia Hub reserves the right to update and/or amend this Privacy Policy, in its sole discretion; therefore, please be advised to check Privacy Policy from time to time in case as such, Konzortia Hub will release user information update (the “Release”) and any further engagement with Konzortia Hub upon such Release shall mean that the user(s) have accepted the amended/updated Privacy Policy
            </p>
            <p className="py-4">Persons under the age of 18 years are prohibited from using Konzortia Hub and any associated services provided therein; persons under the age of 18 years are therefore not authorized to submit any and all private information to the system and Konzortia Hub has no liability and/or responsibility for any and all such actions.
            </p>
            <p className="py-4">Upon registering as a Konzortia Hub user, certain contact information such as your name, email address, mailing address and other information may be collected. This serves as an example and intended to be a complete and extensive list of information that may be collected by Konzortia Hub at registration.
            </p>
            <p className="py-4">
              <p className="font-bold">Konzortia Hub</p>
              Konzortia Hub is an online service platform that allows Users to set-up an investment criterion to match curated deals leverage on Konzortia Hub´s artificial intelligence platform. Only users who are manually approved by Konzortia Hub administration will be able to access the Site.
            </p>
            <p className="py-4">
              <p className="font-bold">System Emails</p>
              As part of the normal functions of the Konzortia Hub Platform, we will send you email notices regarding your profile information on the Site. We will try to keep these emails limited to a minimum and only necessary.
            </p>
            <p className="py-4">
            Konzortia Hub will send you system generated emails, information regarding policies of the Platform and/or promotional materials (the “Communications”). These Communications may begin during the registration process and continue while your account remains active on the Konzortia Hub platform.
            </p>
            <p className="py-4">
              <p className="font-bold">Internet</p>
              As part of our efforts to provide better services to Konzortia Hub users, Konzortia Hub collects certain information related to the Internet and/or your computer. For us to analyze trends and provide superior service to our users, Konzortia Hub retrieves the web address (URL) of both the website that you visited directly before and directly after visiting the Konzortia Hub site. We also receive information about your personal computer such as the unique IP address. Konzortia Hub also follows a standard practice of using cookies and logs to analyze website usage; an extremely small files that are stored on your computer and help us to provide you with a better experience by automatically recalling your preferences each time you visit or use our Site. Cookies will not harm your computer in any way and can be deleted by you at any time whatsoever.
            </p>
            <p className="py-4">
              <p className="font-bold">Profile</p>
              Following registration, Konzortia Hub users will be prompted to complete a profile. Current users can also make changes to their own user profile. A user profile may contain but is not limited to any information or statement of Users based on specific information requested by Konzortia Hub in order to fit the investment criteria of investors or projects using Konzortia Hub. You should use good judgment about the information that you post in your profile since this can be viewed by other User(s) on Konzortia Hub. Your personal contact information CANNOT be viewed unless you release this information (you have full control over the privacy of this section in the user profile). Additionally, the information in your profile can be edited at your discretion at any time
            </p>
            <p className="py-4">
              <p className="font-bold">Account Preferences</p>
              You may make changes to your Konzortia Hub account and any associated preferences at any time. To make changes, you must log onto Konzortia Hub and make any edits through the My Account section. You may also request to delete your Konzortia Hub account. To request a removal of your account, please contact us here.
            </p>
            <p className="py-4">
              <p className="font-bold">Abuse</p>
              By entering into our User Agreement, Konzortia Hub Users agree to not abuse other Users’ personal information. Abuse is defined as using any User’s personal information for any purpose other than those explicitly specified in the Terms of Services (the “Terms of Services) or not related to fulfilling delivery of a product or service explicitly specified in the Terms of Services of Konzortia Hub.
            </p>
            <p className="py-4">
              <p className="font-bold">Personal Data</p>
              Personal Data shall be processed and stored for as long as required by the purpose they have been collected for. Therefore: Personal Data collected for purposes related to the performance of a contract between Konzortia Hub, and the User shall be retained until such contract has been fully performed; Personal Data collected for the purposes of Konzortia Hub legitimate interest shall be retained as long as needed to fulfill such purposes. Konzortia Hub may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, Konzortia Hub may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.
            </p>
            <p className="py-4">
              Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after retention’s period expiration.
            </p>
            <p className="py-4">
              What are the purposes for which we use your personal information? We may use your personal information to allow the Service’s operational functioning and features (“business purposes”). In such cases, your personal information will be processed in a fashion necessary and proportionate to the business purpose for which it was collected and strictly within the limits of compatible operational purposes.
            </p>
            <p className="py-4">
              We may also use your personal information for other reasons such as for commercial purposes, as well as for complying with the law and defending our rights before the competent authorities where our rights and interest are threatened, or we suffer actual damage. We will not use your personal information for different, unrelated, or incompatible purposes without notifying you
            </p>
            <p className="py-4">
              <p className="font-bold">Methods of processing</p>
              Konzortia Hub takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data. Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to Konzortia Hub, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Service (administrative, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT Companies, communications agencies) appointed, if necessary, as Data Processors by Konzortia Hub.
            </p>
            <p className="py-4">
              <p className="font-bold">Questions; Requests</p>
              If you have a question about this Privacy Policy, please contact us at the following email or address:
            </p>
            <p className="py-4">Management@konzortiacapital.com Konzortia Capital INC.</p>
            <p className="py-4">99 Wall Street #5895</p>
            <p className="py-4">New York, NY 10005</p>
            <p className="py-4">Any requests to exercise User rights can be directed to Konzortia Hub through the contact details provided above. These requests can be exercised free of charge and will be addressed by Konzortia Hub as early as possible and always within one month.</p>
            <p className="py-4">Your California privacy rights and how to exercise them The right to know and to portability You have the right to request that we disclose to you:</p>
            <p className="py-4">The categories and sources of personal information that we collect about you, the purposes for which we use your information, with whom we use your information, and with whom such information is shared; In case of personal information´s sale or disclosure for business purposes, two separate lists where we disclose: For sale, the personal information categories purchased by each category of the recipient; and For disclosure for a business purpose, the personal information categories obtained by each category of receipt. The disclosure described above will be limited to the personal information collected or used over the past 12 months.</p>
            <p className="py-4">If we deliver our responses electronically, the information enclosed will be “portable”, i.e., delivered in an easily usable format to enable you to transmit the information to another entity without hindrance - provided that this is technically feasible.</p>
            <p className="py-4">The right to request the deletion of your personal information You have the right to request that we delete any or all of your personal information, subject to exceptions set forth by the law (such as including but not limited to, where the information is used to identify and repair errors on this Service, to detect security incidents and protect against fraudulent or illegal activities, to exercise certain rights, etc.).</p>
            <p className="py-4">If no legal exception applies we will delete your personal information and direct any of our service providers to do so as a result of exercising your right.</p>
            <p className="py-4">
              <p className="font-bold">How to exercise your rights</p>
              To exercise the rights described above, you need to submit your verifiable request by contacting us via the details provided in this document. For us to respond to your request, it is necessary that we know who you are. Therefore, you can only exercise the above rights by making a verifiable request which must:
            </p>
            <p className="py-4">Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative; Describe your request with sufficient detail that allows us to understand, evaluate, and respond to it properly. If you are an adult, you can make a verifiable request on behalf of a minor under your parental authority.</p>
            <p className="py-4">You can submit a maximum number of 2 requests over a period of 12 months.</p>
            How and when we are expected to handle your request We will confirm receipt of your verifiable request within 10 days and provide information about how we will process your request.
            <p className="py-4">We will respond to your request within 45 days of its receipt. Should we need more time, we will explain to you the reasons why, and how much more time we need. In this regard, please note that we may take up to 90 days to fulfill your request. Our disclosure(s) will cover the preceding 12 month period.</p>
            <p className="py-4">Should we deny your request, we will explain to you the reasons behind our denial. We do not charge a fee to process or respond to your verifiable request unless such request is manifestly unfounded or excessive. In such cases, we may charge a reasonable fee or refuse to act on the request. In either case, we will communicate our choice and explain the reasons behind it.</p>
            <p className="py-4">Information for Users residing in Brazil This part of the document integrates with and supplements the information contained in the rest of the privacy policy and is provided by the entity running this Service and, if the case may be, its parent, subsidiaries, and affiliates (for the purposes of this section referred to collectively as “we”, “us”, “our”).</p>
            <p className="py-4">The provisions contained in the section apply to all Users who reside in Brazil, according to the “Lei Geral de Proteção de Dados” Users are referred to below, simply as “you”, “your”, “yours”). For such Users, these provisions supersede any other possibly divergent or conflicting provisions contained in the privacy policy.</p>
            <p className="py-4">This part of the document uses the term “personal information” as it is defined in the “Lei Geral de Proteção de Dados” LGPD.</p>
            <p className="py-4">The grounds on which we process your personal information We can process your personal information solely if we have a legal basis for such processing. Legal bases are as follows:</p>
            <p className="py-4">Your consent to the relevant processing activities; Compliance with a legal or regulatory obligation Controller) that lies with us; Carrying out of public policies provided in laws or regulations or based on contracts, agreements, and similar legal instruments; Carrying out studies conducted by research entities, preferably carried out on anonymized personal information; Carrying out of a contract and its preliminary procedures, in cases where you are a party to the said contract; Exercising of our rights in judicial, administrative, or arbitration procedures; -Protection of the life or physical safety of yourself or a third party; Health protection — in procedures carried out by health entities or professionals; Fulfil our legitimate interests, provided that your fundamental rights and liberties do not prevail over such interests; and Credit protection To find out more about the legal bases, you can contact us at any time using the contact details provided in this document.</p>
            <p className="py-4">
              <p className="font-bold">Categories of personal information processed</p>
              To find out what categories of your personal information are processed, you can read the section titled “Detailed information on the processing of Personal Data” within this document.
            </p>
            <p className="py-4">Why we process your personal information To find out why we process your personal information is processed, you can read the section titled “Detailed information on the processing of Personal Data” and “The purposes of processing” within this document.</p>
            <p className="py-4">Your Brazilian privacy rights You have the right to:</p>
            <p className="py-4">Obtain confirmation of the existence of processing activities on your personal information; Have incomplete, inaccurate, or outdated personal information rectified/corrected; Obtain the anonymization, blocking, or elimination of your unnecessary or excessive personal information or of information that is not being processed in compliance with the LGPD; Obtain information on the possibility to provide or deny your consent and the consequences thereof; Obtain information about the third parties with whom we share your personal information; Obtain, upon your express request, the portability of your personal information (except for anonymized information) to another service or product provider, provided that our commercial and industrial secrets are safeguarded; Obtain the deletion of your personal information being processed if the processing was based upon your consent, unless one or more exceptions are provided for in art. 16 of the LGPD apply; Revoke your consent at any time; Lodge a complaint related to your personal information with the ANPD (the National Data Protection Authority) or with consumer protection bodies; Oppose a processing activity in cases where the processing is not carried out in compliance with the provisions of the law; Request clear and adequate information regarding the criteria and procedures used for an automated decision; and Request the review of decisions made solely based on automated processing of your personal information, which affects your interests. These include decisions to define your personal, professional, consumer, and credit profile or aspects of your personality. You will never be discriminated against or otherwise, suffer any sort of detriment if you exercise your rights.</p>
            <p className="py-4">
              <p className="font-bold">How to file your request</p>
              You can file your express request to exercise your rights free from any charge, at any time, by using the contact details provided in this document or via your legal representative.
            </p>
            <p className="py-4">How and when we will respond to your request We will strive to respond to your requests promptly.</p>
            <p className="py-4">In any case, should it be impossible for us to do so, we will make sure to communicate to you the factual or legal reasons that prevent us from immediately, or otherwise ever, complying with your requests. In cases where we are not processing your personal information, we will indicate to you the physical or legal person to whom you should address your requests if we are in the position to do so.</p>
            <p className="py-4">In the event that you file an access or personal information processing confirmation request, please make sure that you specify whether you would like your personal information to be delivered in electronic or printed form.</p>
            <p className="py-4">You will also need to let us know whether you want us to answer your request immediately, in which case we will answer in a simplified fashion, or if you need a complete disclosure instead.</p>
            <p className="py-4">In the latter case, we will respond within 15 days from the time of your request, providing you with all the information on the origin of your personal information, confirmation on whether or not records exist, any criteria used for the processing and the purposes of the processing, while safeguarding our commercial and industrial secrets.</p>
            <p className="py-4">In the event that you file a rectification, deletion, anonymization, or personal information blocking request, we will make sure to immediately communicate your request to other parties with whom we have shared your personal information in order to enable such third parties to also comply with your request – except in cases where such communication is proven impossible or involves a disproportionate effort on our side.</p>
            <p className="py-4">Transfer of personal information outside of Brazil permitted by the law We are allowed to transfer your personal information outside of the Brazilian territory in the following cases:</p>
            <p className="py-4">When the transfer is necessary for international legal cooperation between public intelligence, investigation and prosecution bodies, according to the legal means provided by the international law; When the transfer is necessary to protect your life or physical security or those of a third party; When the transfer is authorized by the ANPD; When the transfer reslits from a commitment undertaken in an international cooperation agreement; When the transfer is necessary for the execution of a public policy or legal attribution of public service; When the transfer is necessary for compliance with a legal or regliatory obligation, the carrying out of a contract or preliminary procedures related to a contract, or the regliar exercise of rights in judicial, administrative, or arbitration procedures. Information for Users residing in EU This part of the document integrates with and supplements the information contained in the rest of the privacy policy and is provided by the entity running this Service and, if the case may be, its parent, subsidiaries, and affiliates (for the purposes of this section referred to collectively as “we”, “us”, “our”).</p>
            <p className="py-4">The provisions contained in the section apply to all Users who reside within the EU Users are referred to below, simply as “you”, “your”, “yours”). For such Users, these provisions supersede any other possibly divergent or conflicting provisions contained in the privacy policy.</p>
            <p className="py-4">This part of the document uses the term “data subject” and “controller” as it is defined in the General Data Protection Regliation GDPR.</p>
            <p className="py-4">The grounds on which we process your personal information We can process your personal information solely if we have a legal basis for such processing. Legal bases are as follows:</p>
            <p className="py-4">Consent from data subject to the processing of Personal Data for one or more specific purposes; Processing necessary for the performance of a contract to which the data subject is a party or in order to take steps at the request of the data subject prior to entering into a contract; Processing is necessary for compliance with a legal obligation to which the controller is subject; Processing is necessary in order to protect the vital interests of the data subject or another natural person; Processing is necessary for the performance of a task carried out in the public interest or the exercise of official authority vested in the controller; Processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require personal data protection in particular where the data subject is a child. Categories of personal information processed To find out what categories of your personal information is processed, you can read the section titled “Detailed information on the processing of Personal Data” within this document.</p>
            <p className="py-4">Why we process your personal information To find out why we process your personal information are processed, you can read the section titled “Detailed information on the processing of Personal Data” and “The purposes of processing” within this document.</p>
            <p className="py-4">Your GDPR privacy rights You have the right to:</p>
            <p className="py-4">Right to rectification; Right of erasure (“right to be forgotten”) Right of restriction of processing; Notification obligation regarding rectification or erasure of personal data or restriction of processing; Right to data portability; Right to object and automated individual decision-making Right to object; Automated individual decision-making, including profiling Right to withdraw data processing consent. Each of these rights and the terms used to describe them has the same meaning as defined in the General Data Protection Regulation GDPR.</p>
            <p className="py-4">How to file your request You can file your express request to exercise your rights free from any charge, at any time, by using the contact details provided in this document or via your legal representative.</p>
            <p className="py-4">How and when we will respond to your request We will strive to respond to your requests promptly</p>
            <p className="py-4">
              <p className="font-bold">Contact Information</p>
              If you have any questions or would like to report any violations of these Terms of Service or other policies set forth by Konzortia Hub please contact the following: Attn:Terms of Service
              <p className="py-4">Management@konzortiacapital.com</p>
              <p className="py-4">Konzortia Capital INC. 99 Wall Street #5895 New York, NY 10005.</p>
            </p>
          </span>
        </div>
      </div>
      

    </>
  );
};
