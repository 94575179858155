import React from 'react';
import { Helmet } from 'react-helmet';

export const Tags = () => { 
  return (
    <div>
      <Helmet>
        <title>Family Office Solutions by Konzortia Capital: Tailored Strategies for Private Wealth Growth</title>
        <meta name="description" content="Konzortia Capital offers tailored investment solutions for family offices, providing exclusive access to private market opportunities, advanced deal sourcing, and wealth growth strategies." />
      </Helmet>
    </div>
  );
};
