import React, { useState } from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ContactCard } from './ContactUs';
import { ContactUs } from '../Home/ContactUs';
import { toast } from 'react-toastify';

export const Description = () => {
  const [loader2 , setLoader2] = useState(false);
  const settings = {
    dots: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: true,
  };
  const faq_items = [
    {
      title:
        'What makes you different from existing competitors in the market?',
      content:
        "What sets us apart from existing competitors is our comprehensive model that encompasses all the steps and requirements of capital raising and allocation within a unified solution. Utilizing AI, ML, and Distributed Ledger technology, we provide transparency, access to reliable data, precise deal discovery tailored to each investor's needs, thorough due diligence, and seamless deal completion, while also offering access to a well-defined exit strategy – all integrated into a single solution.",
    },
    {
      title: "Do you wonder if there's market fit?",
      content:
        "We've successfully engaged with a diverse range of investors, spanning from individual accredited investors to Family Offices, Venture Funds, PE firms, broker dealers, and companies at various stages, from idea to large corporations with substantial revenue seeking funding or an exit strategy. This engagement has allowed us to gather early feedback during our development phase. The consensus among these stakeholders is clear – there's a genuine market need to address the inefficiencies in the Private Capital Markets industry, and Konzortia Hub, with its well-crafted pricing model, robust technology, and innovative features, is poised to effectively tackle these inefficiencies.",
    },
    {
      title: 'Is the team capable of performing?',
      content:
        'My co-founder and I strongly advocate for maintaining a lean and highly efficient team. One of our key strengths is our ability to attract and retain top-notch talent. Our compact yet high-performing team not only brings extensive experience in their respective fields but also shares our vision and commitment to establishing Konzortia Hub as the long-awaited market leader in the Private Capital Markets industry. We firmly believe that strategically expanding our team, driven by genuine needs for increased productivity, while maintaining a thoughtful approach to our budget, is undeniably the right strategy.',
    },
    {
      title:
        'Who is your target and how is that applied to your go-to-market strategy?',
      content:
        'Konzortia Hub is strategically positioned to serve various segments within the private capital market industry, catering to the diverse needs of allocators, intermediaries, and issuers. We recognize that different age demographics exhibit varying levels of comfort with technology, with those under 50 years old generally displaying greater proficiency and openness to digital solutions. However, we understand that long-standing practices within the industry, such as conducting deals through face-to-face meetings, may lead to initial resistance to technological adoption, particularly among established firms with decades of experience in Private Equity and Mergers and Acquisitions (PE & M&A).To overcome these challenges, we are committed to establishing Konzortia Hub as a reputable and trusted partner within the industry. While targeting VC deals represents low-hanging fruit, once our reputation has been established, we aim to expand our reach to encompass other segments such as Private Equity and Mergers and Acquisitions. Our value proposition lies in providing an intuitive, secure, and efficient platform that enhances decision-making processes and fosters collaboration among stakeholders.',
    },
    {
      title: 'How will you acquire critical mass?',
      content:
        "Our company is committed to achieving revenue within the first 6 months of operations, with the aim of reaching the break-even point within the first 12 months. We firmly believe that validation is crucial for gaining critical mass. To achieve this, we are actively cultivating partnerships with reputable companies serving our target segments. Additionally, we're leveraging partnerships with incubators and accelerators to secure high-quality deal flow from issuers. Drawing from analyses of both successful and unsuccessful referral programs, we are confident that a well-crafted referral program can significantly impact our acquisition costs and accelerate our growth.Our confidence in the market need for our offering stems not from mere belief but from a proven model. Prior to acquisition, Paraforge demonstrated significant revenue generation through their designed directory alone. Building on this foundation and informed by our deep market knowledge, we are equipped to tailor our messaging to resonate with each audience through specific channels, facilitating swift engagement. By employing well-designed messaging, a robust sales funnel, and a comprehensive press strategy, we are poised to exceed our targets in terms of user acquisition, cash flow, and revenue.",
    },
    {
      title: 'What are the revenue streams?',
      content:
        "Our revenue model operates on two fronts: a Software as a Service (SaaS) model with a subscription base and a success fee model, akin to investment banking. In both cases, fees are paid by the issuers.Given our primary focus on the US market, we've identified a lucrative opportunity by expanding our reach to international startups. Countries like India and regions such as Latin America present fertile ground, with startups seeking funding likely to find immense value in accessing our extensive directory of over 800,000 investors for a nominal subscription fee.<br/>Confident in our model, we anticipate that within our AI & ML powered marketplace, deals will transpire more efficiently, allowing us to capitalize on collecting success fees. To facilitate this, later this year we will start the process of registering as a Broker-Dealer with FINRA.",
    },
    {
      title: 'Is the tech developed in-house?',
      content:
        "Yes, we have our own development team. After acquiring Paraforge’s AI engine, which was primarily focused on connecting GPs and LPs, we've been diligently enhancing their directory. This includes creating a more user-centric UI/UX and redesigning all features to better suit the specific needs of VC, PE & M&A participants.<br/>In August 2023, we signed a partnership with the Indian development company Tecxar. They specialize in AI, ML, and blockchain development tailored for both public and private markets. Tecxar boasts several success stories, having developed products for startups that rapidly achieved unicorn status. Since the agreement, they have been working on integrating new AI and ML capabilities into Konzortia Hub. The deal was structured with a significant equity component and a smaller cash component.<br/>We anticipate launching Konzortia Hub's first release within the next month, with a final release expected by the end of the year",
    },
    {
      title: 'Where do you see your company in 5 years from now?',
      content:
        'We possess a clear understanding of how to achieve sustainable growth. Drawing from our collective experience, we are well-versed in the steps necessary to accomplish our goals. While maintaining our focus on the present, we have outlined a clear path forward, complete with key performance indicators (KPIs) to guide our progress.<br/> In five years, we envision Konzortia Capital emerging as an industry leader, driven by our subsidiary, Konzortia Hub. We anticipate achieving significant market penetration, establishing ourselves as the standard-bearer in Private Capital Markets for primary transactions. Furthermore, through Konzortia Hub OTC, we aim to dominate the secondaries market. Konzortia Hub Terminal is poised to become indispensable for brokers operating in the secondary market, while our aggregators for primary and secondary transactions will unify what is currently a fragmented market.<br/> In essence, our vision is to transform private markets, ushering in greater transparency, efficiency, and accessibility, mirroring the characteristics currently prevalent in public markets.. We are dedicated to staying at the forefront of innovation, thereby establishing significant barriers to entry for potential competitors. We believe this strategic approach reflects the secret sauce of multi-billion-dollar companies that emerged from humble beginnings just decades ago.',
    },
    {
      title: 'Exit Strategy',
      content:
        "With the establishment of Konzortia Hub’s OTC, characterized as a secondary market registered as an ATS but with all the features of an exchange, our investors will gain access to liquidity through secondary transactions. However, we do not rule out the possibility of an IPO in the future. Moreover, once we have secured a significant market share and have made substantial impact, it's probable that major players in the investment banking industry will approach us for a buyout, which we will consider on a case-by-case basis.",
    },
  ];
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const handleClick = (index: number) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  const showSuccessNotification = (message: any) => {
    toast.success(message);
  };

  const showErrorNotification = (error: any) => {
    toast.error(error);
  };

  const [form_competitor, set_form_competitor] = useState({
    name: '',
    email: '',
    phone: '',
    type: 'analysis_competitor_kc',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    set_form_competitor({ ...form_competitor, [name]: value });
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent form submission
    setLoader2(true);
    try {
      fetch('https://konzortiacapital.com/save.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form_competitor),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message == 'Data received successfully') {
            setLoader2(false);
            showSuccessNotification(
              'Successfully submitted, We will contact you soon'
            );
            set_form_competitor({
              name: '',
              email: '',
              phone: '',
              type: 'analysis_competitor_kc',
            });
          } else {
            showErrorNotification('An error Occured, Try again later!');
            set_form_competitor({
              name: '',
              email: '',
              phone: '',
              type: 'analysis_competitor_kc',
            });
            setLoader2(false);
          }
        });
    } catch (error) {
     setLoader2(false);
      showErrorNotification('An error Occured, Try again later!');
    }
  };
  return (
    <>
      <div className='px-4 mx-auto container w-12/12 bg-white '>
        <div className='flex flex-col lg:flex-row '>
          <div className='lg:w-8/12 w-12/12 leading-loose pt-12 px-12 flex flex-col content-left justify-left text-left'>
            <span className='font-primary font-size-30 color-dark-blue font-bold py-4'>
              What is Konzortia Capital?{' '}
            </span>
            <span className='font-primary font-size-16 color-dark-blue '>
              Konzortia Capital is a groundbreaking holding company at the
              forefront of revolutionizing Private Capital Markets through its
              subsidiary, Konozrtia Hub. Konzortia Hub, the flagship product of
              Konzortia Capital, stands as a catalyst for this transformative
              journey. Leveraging the power of AI, ML, and Distributed Ledger
              technologies, Konzortia Hub is reshaping the landscape of deal
              sourcing, evaluation, and execution processes within the realms of
              Venture Capital (VC), Private Equity (PE), and Mergers &
              Acquisitions (M&As).
            </span>
            <span className='font-primary font-size-22 color-dark-blue font-bold py-4'>
              Transforming Private Capital Markets: One Process at a Time
            </span>
            <span className='font-primary font-size-16 color-dark-blue '>
              With a mission to reshape the landscape of private capital
              markets, Konzortia Hub goes beyond leveraging cutting-edge technology
              to bridge the gaps in the traditional market. It offers a
              streamlined, efficient, and secure platform that distinguishes
              itself from competitors. Unlike others, Konzortia Hub empowers both
              allocators and issuers to navigate seamlessly from A to Z. The
              platform is meticulously designed, considering the unique user
              experience and specific needs of various issuer and allocator
              profiles, making it a truly unique solution in the industry.
            </span>
            <div className='flex flex-col pt-24'>
              <div className='flex flex-row items-center'>
                <div className='px-4 py-4'>
                  <img src='/sourceandmatch.svg' alt='konzortia source and match' />
                </div>
                <div className='pl-4' style={{ width: '660px' }}>
                  <span
                    className='font-primary font-size-22 color-dark-blue font-bold py-4'
                    style={{ display: 'block' }}
                  >
                    Source & Match
                  </span>

                  <span
                    className='font-primary font-size-16 color-dark-blue'
                    style={{ display: 'block' }}
                  >
                    Access a curated deal flow with precise matches powered by
                    AI & ML, tailored to your investment criteria.
                  </span>
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <div className='px-4 py-4'>
                  <img src='/dataanalysis.svg' alt='konzortia data analysis' />
                </div>
                <div className='pl-4' style={{ width: '660px' }}>
                  <span
                    className='font-primary font-size-22 color-dark-blue font-bold py-4'
                    style={{ display: 'block' }}
                  >
                    Data Access & Analysis
                  </span>

                  <span
                    className='font-primary font-size-16 color-dark-blue'
                    style={{ display: 'block' }}
                  >
                    Utilize industry data, benchmarking tools, and automated AI
                    analysis for comprehensive evaluation.
                  </span>
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <div className='px-4 py-4'>
                  <img src='/digitize.svg' alt='konzortia digitize'/>
                </div>
                <div className='pl-4' style={{ width: '660px' }}>
                  <span
                    className='font-primary font-size-22 color-dark-blue font-bold py-4'
                    style={{ display: 'block' }}
                  >
                    Execute & Digitize
                  </span>

                  <span
                    className='font-primary font-size-16 color-dark-blue'
                    style={{ display: 'block' }}
                  >
                    Streamline the entire process. Sign deals, distribute funds,
                    and convert positions into digital form, complete with a
                    CUSIP-like denominator on our Distributed Ledger.
                  </span>
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <div className='px-4 py-4'>
                  <img src='/exit.svg' alt='exit'/>
                </div>
                <div className='pl-4' style={{ width: '660px' }}>
                  <span
                    className='font-primary font-size-22 color-dark-blue font-bold py-4'
                    style={{ display: 'block' }}
                  >
                    Exit / Trade
                  </span>
                  <span
                    className='font-primary font-size-16 color-dark-blue'
                    style={{ display: 'block' }}
                  >
                    Explore liquidity events through our Secondary Market,
                    offering a Bloomberg-like terminal for unprecedented
                    analysis and execution in Private Capital Markets.
                  </span>
                </div>
              </div>
            </div>
            <span className='font-primary font-size-30 color-dark-blue font-bold py-10'>
              {' '}
              How are we different?
            </span>
            <span className='font-primary font-size-16 color-dark-blue '>
              At Konzortia Hub, AI is not just a buzzword—it&apos;s an essential
              component woven into the fabric of our business model. Unlike those
              who merely adopt AI as a trend, our model was designed with AI as a
              necessity, recognizing the pivotal role it plays in addressing the
              challenges of the Private Capital Markets.
              <br />
              In tackling the significant hurdles of this industry, Konzortia Hub
              presents a thoughtfully devised plan, introducing a set of features
              tailored to navigate the complexities across various verticals. Our
              approach is meticulous, addressing the specific needs of key
              players in the market. Key areas we focus on include:
            </span>
            <div className='flex flex-col pt-24'>
              <div className='flex flex-row py-4'>
                <div className='px-4 py-4'>
                  <img src='/checkmark.svg' alt='konzortia' className='fitwidth' />
                </div>
                <div>
                  <span className='font-primary color-dark-blue'>
                    <b>Sourcing and Matching Deals (AI/ML):</b> Leveraging
                    advanced AI and ML algorithms to optimize deal sourcing and
                    matching.
                  </span>
                </div>
              </div>
              <div className='flex flex-row py-4'>
                <div className='px-4 py-4'>
                  <img src='/checkmark.svg' alt='konzortia' className='fitwidth' />
                </div>
                <span className='font-primary color-dark-blue'>
                  <b>Access to Real-Time Data and Benchmarking (AI):</b>{' '}
                  Providing access to reliable, real-time data and benchmarking
                  tools for efficient deal evaluation through the power of AI.{' '}
                </span>
              </div>
              <div className='flex flex-row py-4'>
                <div className='px-4 py-4'>
                  <img src='/checkmark.svg' alt='konzortia' className='fitwidth' />
                </div>
                <span className='font-primary color-dark-blue'>
                  <b>Secure and Traceable Transaction Execution:</b> Leveraging
                  CUSIP-like codes, our blockchain technology ensures
                  traceability and transparency throughout the transaction
                  process.{' '}
                </span>
              </div>
              <div className='flex flex-row py-4'>
                <div className='px-4 py-4'>
                  <img src='/checkmark.svg' alt='konzortia' className='fitwidth' />
                </div>
                <span className='font-primary color-dark-blue'>
                  <b>Liquidity Access through Secondary Trading:</b>{' '}
                  Facilitating access to liquidity and liquid opportunities
                  through secondary trading.{' '}
                </span>
              </div>
              <div className='flex flex-row py-4'>
                <div className='px-4 py-4'>
                  <img src='/checkmark.svg' alt='konzortia' className='fitwidth' />
                </div>

                <span className='font-primary color-dark-blue'>
                  <b>New Standards for an Unorganized Market:</b> Introducing
                  capabilities to establish standardized processes across the
                  industry.{' '}
                </span>
              </div>
              <div className='flex flex-row py-4'>
                <div className='px-4 py-4'>
                  <img src='/checkmark.svg' alt='konzortia' className='fitwidth' />
                </div>
                <span className='font-primary color-dark-blue'>
                  <b>Unifying a Highly Fragmented Market:</b> Our aggregator,
                  powered by an API, will bridge various platforms and
                  marketplaces, creating a unified market experience.{' '}
                </span>
              </div>
            </div>
            <span className='font-primary font-size-30 text-black font-bold py-10'>
              This Investment Opportunity in a Nutshell
            </span>
            <span className='font-primary font-size-22 color-primary font-bold'>
              Traction and Momentum Highlights:
            </span>
            <dl>
              <dt className='font-primary font-size-16 color-dark-blue font-bold py-4'>
                1. Implementation of Acquired Paraforge AI Engine:
              </dt>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Acquired Paraforge AI/ML Engine for $5.2M in 2022.
              </dd>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Paraforge boasted over $100M in sales pre-acquisition.
              </dd>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Successful integration of its UI/UX to serve VC, PE & M&As.
              </dd>
              <dt className='font-primary font-size-16 color-dark-blue font-bold py-4'>
                2. Key Partnerships and Development:
              </dt>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Formed a pivotal partnership with Tecxar in Q4 2023.
              </dd>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Tecxar&apos;s $512K investment significantly advanced
                Konzortia Hub&apos;s development.
              </dd>
              <dt className='font-primary font-size-16 color-dark-blue font-bold py-4'>
                3. Successful Funding Rounds:
              </dt>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Concluded a Pre-Seed Round, securing over $1M in combined debt
                and equity.
              </dd>
              <dt className='font-primary font-size-16 color-dark-blue font-bold py-4'>
                4. Family Office Investment:
              </dt>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Secured a substantial deal with a leading European Family
                Office.
              </dd>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Initial investment of $4.2M with potential to reach $15M by mid
                2025, subject to KPIs.
              </dd>
              <dt className='font-primary font-size-16 color-dark-blue font-bold py-4'>
                5. Organic Business Development and Engaged Beta Users:
              </dt>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Meticulous organic business development efforts.
              </dd>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Initiated conversations for 3 potential partnerships to be
                completed in the next few weeks.
              </dd>
              <dd className='font-primary font-size-16 color-dark-blue px-10'>
                Engaged notable Beta Users, including:{' '}
              </dd>
            </dl>
            <Slider {...settings}>
              <div className='py-10 px-10'>
                <ul
                  className='responsive-list'
                  style={{ display: 'inline-block' }}
                >
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Murdock Capital
                  </li>
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Synergy To Value
                  </li>
                </ul>
                <ul
                  className='responsive-list'
                  style={{ display: 'inline-block' }}
                >
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Neuraptic AI
                  </li>
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Novastrone Capital Advisors
                  </li>
                </ul>
              </div>
              <div className='py-10 px-10'>
                <ul
                  className='responsive-list'
                  style={{ display: 'inline-block' }}
                >
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Hustle Fund
                  </li>
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    6350 Capital GroupC
                  </li>
                </ul>
                <ul
                  className='responsive-list '
                  style={{ display: 'inline-block' }}
                >
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Venture DNA
                  </li>
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Ramcorp, LLC
                  </li>
                </ul>
              </div>
              <div className='py-10 px-10'>
                <ul
                  className='responsive-list'
                  style={{ display: 'inline-block' }}
                >
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Mocha Ventures
                  </li>
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Citizen Capital
                  </li>
                </ul>
                <ul
                  className=' responsive-list'
                  style={{ display: 'inline-block' }}
                >
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Cultivate Advisor
                  </li>
                  <li className='font-primary font-size-16 color-dark-blue'>
                    <img
                      src='/rec.svg'
                      alt='konzortia'
                      className='px-4'
                      style={{ display: 'inline-block' }}
                    />
                    Quantum Capital Investment
                  </li>
                </ul>
              </div>
            </Slider>
            <div>
              <span className='font-secondary font-size-22 color-dark-blue py-10'>
                Become a Visionary Investor, Paving the Way for the Future of
                Private Capital Markets
              </span>
            </div>
            <button
              className='btn-primary font-primary font-bold color-white font-size-16 px-10'
              style={{ marginLeft: '5 rem', width: '100%', height: 'auto' }}
              onClick={() =>
                (window.location.href =
                  'https://calendly.com/walter-gomez-kc/priority-investor-zoom-meeting-with-the-found-clone-1/')
              }
            >
              Schedule an introductory meeting today
            </button>
            <div>
              <div style={{ paddingTop: '2.5rem', paddingBottom: '5rem' }}>
                <div
                  className='flex flex-row font-mode-underlined'
                  style={{ justifyContent: 'center' }}
                >
                  <span className='font-primary font-bold font-size-24  color-dark-blue'>
                    FAQ
                  </span>
                </div>
                {faq_items.map((item: any, index: number) => (
                  <div key={item.title} style={{ paddingBottom: '1rem' }}>
                    <div
                      onClick={() => handleClick(index)}
                      className='flex flex-row'
                      style={{ paddingBottom: '1rem' }}
                    >
                      <span className='font-primary font-bold font-size-16 color-dark-blue'>
                        {item.title}
                      </span>
                      <div>
                        <img src='/expand.png' alt='konzortia' className='fitwidth' />
                      </div>
                    </div>
                    {index === activeIndex && (
                      <span
                        className='font-primary font-size-16 color-dark-blue px-10'
                        style={{ paddingBottom: '1rem' }}
                      >
                        {item.content}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='py-12 w-12/12 lg:w-4/12'>
            <ContactUs />
            <div style={{ paddingTop: '2.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-bold font-size-22 color-dark-blue'>
                  REVIEW OUR ONEPAGER
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/onepage.png' alt='konzortia onepager'/>
              </div>
              <div>
                <button
                  className='btn-primary font-primary font-bold color-white font-size-16'
                  style={{ width: '150px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://docsend.com/view/65ytphyfnb4ymzdh')
                  }
                >
                  Onepager
                </button>
              </div>
            </div>
            <div style={{ paddingTop: '2.5rem' }}>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/video1.png' alt='konzortia' />
              </div>
            </div>
            <ContactCard />
            <div className='flex  py-10 px-2 w-12/12 '>
              <div className='social-media-card w-100 h-100'>
                <div className='flex flex-row w-12/12 h-100 center-div'>
                  <span className='font-secondary-italic text-white font-size-24'>
                    Follow us!
                  </span>
                  <div className='px-2 color-white'>
                    <a href="https://x.com/konzortia_c"><AiOutlineTwitter size={20} /></a>
                  </div>
                  <div className='px-2 color-white'>
                    <a href="https://www.facebook.com/konzortia"><AiFillFacebook size={20} /></a>
                  </div>
                  <div className='px-2 color-white'>
                    <a href="https://www.linkedin.com/company/konzortiacapital/"><AiFillLinkedin size={20} /></a>
                  </div>
                  <div className='px-2 color-white'>
                    <a href="https://www.instagram.com/konzortia_capital/"><AiOutlineInstagram size={20} /></a>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: '2.5rem', backgroundColor: '#F4F4F4' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-bold font-size-22 color-dark-blue'>
                  REVIEW OUR PITCH DECK
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/investhubpc.png' alt='konzortia hub' />
              </div>
              <div style={{ paddingTop: '1.5rem' }}>
                <button
                  className='btn-primary font-primary font-bold color-white font-size-16'
                  style={{ width: '150px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://docsend.com/view/n5h9qf9rbjx9epsh')
                  }
                >
                  Pitch Deck
                </button>
              </div>
            </div>

            <div style={{ paddingTop: '2.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-size-22 color-dark-blue'>
                  Request Access to our <b>Competitive Analysis</b>
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/competitiveanalysis.png' alt='konzortia competitive analysis' />
              </div>
              <div
                style={{
                  paddingTop: '1.5rem',
                  paddingBottom: '1.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <form className='w-3/4'>
                  <div className='w-12/12'>
                    <div className='flex flex-col'>
                      <div className='flex flex-col'>
                        <label
                          htmlFor='name'
                          className='font-primary font-size-12 color-dark-blue'
                          style={{ textAlign: 'left' }}
                        >
                          Name
                        </label>{' '}
                        <input
                          type='text'
                          name='name'
                          onChange={handleChange}
                          className='form-control w-100 font-primary font-size-12 '
                          style={{ marginTop: '0px' }}
                        />
                      </div>
                      <div className='flex flex-col py-2'>
                        <label
                          htmlFor='email'
                          className='font-primary font-size-12 color-dark-blue'
                          style={{ textAlign: 'left' }}
                        >
                          Email
                        </label>{' '}
                        <input
                          type='text'
                          name='email'
                          onChange={handleChange}
                          className='form-control w-100 font-primary font-size-12 '
                          style={{ marginTop: '0px' }}
                        />
                      </div>
                      <div className='flex flex-col '>
                        <label
                          htmlFor='phone'
                          className='font-primary font-size-12 color-dark-blue'
                          style={{ textAlign: 'left' }}
                        >
                          Phone
                        </label>{' '}
                        <input
                          type='text'
                          name='phone'
                          onChange={handleChange}
                          className='form-control w-100 font-primary font-size-12 '
                          style={{ marginTop: '0px' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: '1.5rem' }} className="flex center-div content-center justify-center">
                    <button
                      className='btn-primary font-primary font-bold color-white font-size-16 flex justify-center content-center'
                      style={{ width: '150px' }}
                      onClick={handleSubmit}
                      disabled={loader2}
                    >
                      {' '}
                      {loader2 ? (
                        <div className='mt-2 spinner'></div>
                      ) : (
                        <span className='mt-1'>Request</span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div style={{ paddingTop: '2.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-bold font-size-22 color-dark-blue'>
                  Take Action
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img
                  src='/video2.png'
                  alt='konzortia'
                  onClick={() =>
                    (window.location.href =
                      'https://docsend.com/view/ue2azbg9r4g93d65')
                  }
                />
              </div>
              <div>
                <span className='font-primary color-dark-blue font-size-16 py-4'>
                  <b>Walter Gomez,</b> CEO & Founder
                </span>
              </div>
              <div>
                <button
                  className='btn-blue font-primary font-bold color-white font-size-12'
                  style={{ width: '300px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://calendly.com/walter-gomez-kc/priority-investor-zoom-meeting-with-the-found-clone-1?month=2024-04')
                  }
                >
                  Schedule a Call with our CEO today
                </button>
              </div>
            </div>

            <div style={{ paddingTop: '2.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary  font-size-22 color-dark-blue'>
                  <i>Request access</i>
                  <br />
                  TO OUR DATA ROOM
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/dataroom.png' alt='konzortia dataroom'/>
              </div>
              <div>
                <button
                  className='btn-primary font-primary font-bold color-white font-size-12'
                  style={{ width: '174px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://dealroom.konzortiacapital.com/')
                  }
                >
                  Data Room
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
