import React from "react";
import {Tags} from "./InvestmentTags";
import Header from "../../components/Header";
import { HeroSection } from "./HeroSection";
import { Footer } from "../../components/Footer";
import { Description } from "./Description";

export const Investment = () => {
  return <>
  <Tags/>
  <Header page="investment"/>
  <HeroSection/>
  <Description/>
  <Footer/>

  </>
};
