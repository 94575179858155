import React from "react";
import {Tags} from "./AngelInvestorTags";
import Header from "../../components/Header";
import { Footer } from "../../components/Footer";
import { HeroSection } from "./HeroSection";
import { Description } from "./Description";


export const AngelInvestor = () => {
  return <>
  <Tags />
  <Header page="angel-investor"/>
  <HeroSection/>
  <Description/>
  <Footer/>

  </>
};
