import React from 'react';

export const Scheduler = () => {
    return(<>
    <div  className='bg-primary'>
        <div className='px-12 py-12 flex center-div flex-col lg:flex-row'>
        
            <span className='font-primary px-12 color-white font-size-22'>
            For more information Schedule a Meeting with our Director of Investor Relations
            </span>
            <button className='btn-secondary uppercase font-primary color-white font-size-16 tracking-wider'
            onClick={() => window.location.href="https://konzortiacapital.com/invest"}
            >Schedule</button>
        </div>
    </div>
    </>)
}