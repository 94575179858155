import React, { useState } from "react";

export interface activePage {
  page: string;
}

export default function NavBar({ page }: activePage) {
  const [navbar, setNavbar] = useState(false);
  
  return (
    <div className="top-0 z-10">
      <div className="w-full overflow-auto px-16  py-8 bg-header shadow-lg">
        <div className="flex justify-between  md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <a
                className="pointer"
                href="https://konzortiacapital.com/"
              >
                <img
                  src="/konzortiaLogoHeader.svg"
                  alt="user-profile"
                  className="mr-1 logo-position"
                />
              </a>
              <div className="md:hidden">
                <button aria-label="menu"
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-black"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-black"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div
            className={`flex text-align-left pb-3 mt-8 md:block md:pb-0 md:mt-0 ml-auto ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="flex flex-col p-4 mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium ml-auto">
              <li
                className={
                  page === "home"
                    ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                    : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                }
              >
                <a href="/" >
                  Home
                </a>
              </li>
              <li
                className={
                  page === "about"
                    ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                    : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                }
              >
                <a href="/about" >
                  About
                </a>
              </li>
              <li
                className={
                  page === "investment"
                    ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                    : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                }
              >
                <a href = '/investment'>
                  Investment
                </a>
              </li>
              <li
                className={
                  page === "family-office"
                    ? "nowrap font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                    : "nowrap font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                }
              >
                <a href="/family-office">
                  Family Office
                </a>
              </li>

              <li
                className={
                  page === "angel-investor"
                    ? "nowrap font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                    : "nowrap font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                }
              >
                <a href="/angel-investor" >
                  Angel Investor
                </a>
              </li>

              <li
                className={
                  page === "careers"
                    ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                    : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                }
              >
                <a href="/careers" >
                  Careers
                </a>
              </li>

              <li
                className={
                  page === "news"
                    ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                    : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                }
              >
                <a href="/blog" >
                  News
                </a>
              </li>
              <li
                className={
                  page === "contact"
                    ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                    : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                }
              >
                <a href="/contact" >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
