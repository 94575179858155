import React, {  useState } from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { ContactUs } from '../Home/ContactUs';
import YoutubeEmbed from '../../components/YoutubeEmbed';

export const Description = () => {
  const [activeAboutPage,setActiveAboutPage] = useState("about");
   return ( <>
      <div
        id='header'
        className='relative w-12/12 flex flex-row w-full'
      >
        {activeAboutPage === 'about' && <img src='/walter&donnie.svg' alt='konzortia' />}
        {activeAboutPage === 'mission' && <img src='/rocket.svg' alt="rocket" />}
        {activeAboutPage === 'members' && <img src='/membersHero.svg' alt="hero" />}
      </div>
      <div className='px-4  mx-auto container w-12/12 bg-white '>
        {activeAboutPage === 'about' && (
          <div className='flex flex-col lg:flex-row '>
            <div className='lg:w-8/12 w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>
              <span className='font-primary font-size-22 text-black font-bold py-4'>
                About Konzortia Capital
              </span>
              <span className='font-primary font-size-16 text-black '>
                Konzortia Capital is a holding company, a FinTech consortium
                providing Venture Capital (VC) / Private Equity (PE) effortless
                Deal Sourcing for Investors and funding opportunities for
                companies from Startups to later stages. As part of our value
                proposition, we promise to Source - Match – Exit. The recent{' '}
                <span
                  className='color-primary underlined pointer' >
                  acquisition of Paraforge
                </span>
                , and its Artificial Intelligence (AI) and Machine Learning (ML)
                technology focused on capital raising and investor or project
                matching, allows Konzortia Capital through its Intellectual
                Property (IP) to provide differentiated services and greater
                operational efficiency for the needs of the Private Capital
                Market sector. Our in-house Distributed Ledger System will
                ensure transactions are transparent, secure, and clear in
                real-time. Konzortia Hub is one of the first mainstream platforms to
                provide transactions for funding and exit strategies.
              </span>
              <span className='font-primary font-size-22 text-black font-bold py-4'>
                Solutions for Efficiency in Private Capital Markets
              </span>
              <span className='font-primary font-size-16 text-black '>
                The Private Capital Markets experience significant challenges
                and some inefficiencies with their operations. There is fierce
                competition and a race to secure the best deals, traditional
                networking and deal sourcing which may not necessarily identify
                all of the best investment opportunities, as well as a shortage
                of staff to plow through data rooms and extensive due diligence
                processes. Konzortia’s AI / ML monitors and analyzes millions of
                data points while scoring projects to match investors’ criteria
                with the companies that are best suited according to historical
                investment behavior. The objective is to enhance efficiency,
                gain speed to market, improve profitability and upside
                potential, and achieve success for both funders and founders.
                Our in-house Distributed Ledger System will ensure transactions
                are transparent, secure, and clear in real-time. Konzortia Hub is
                one of the first mainstream platforms to provide transactions
                for funding and exit strategies.
              </span>
              <span className='font-primary font-size-22 text-black font-bold py-4'>
                Alternative Investments
              </span>
              <span className='font-primary font-size-16 text-black '>
                The alternative markets provide portfolio diversification and
                proven better potential returns on average than traditional
                markets. With the record levels of dry powder ready to be
                allocated, Konzortia Hub can accelerate the investment placement
                process and help founders through the different stages of the
                capitalization life cycle. We screen, score and curate projects
                to improve the probability of success!
              </span>
            </div>
            <div className='py-12 w-12/12 lg:w-4/12'>
              <div className='flex flex-col h-100'>
                <ContactUs />
                <div className='flex center-div flex-col'>
                  
                  <div className='py-2'>
                    <video controls width="600px" height="350px" >
                        <source src="/kevin.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> 
                  </div>
                </div>
                <div className='px-4 py-4'>
                  <div className='bg-gray rounded py-2'>
                    <div
                      className='py-2 px-12 flex flex-row pointer'
                      onClick={() => setActiveAboutPage('mission')}
                    >
                      <img className='px-2' src='/linkIcon.svg' alt="konzortia" />
                      <span className='font-primary color-primary font-bold'>
                        Mission
                      </span>
                    </div>
                    <div
                      className='py-2 px-12 flex flex-row pointer'
                      onClick={() => setActiveAboutPage('members')}
                    >
                      <img className='px-2' src='/linkIcon.svg' alt="konzortia" />
                      <span className='font-primary color-primary font-bold'>
                        Meet the Konzortians
                      </span>
                    </div>
                    <div
                      className='py-2 px-12 flex flex-row pointer'
                      onClick={() => window.location.href=`${process.env.REACT_APP_LOCAL_URL}investment`}
                    >
                      <img className='px-2' src='/linkIcon.svg' alt="konzortia" />
                      <span className='font-primary color-primary font-bold'>
                        Invest in Konzortia
                      </span>
                    </div>
                  </div>
                </div>
                <div className='flex  py-10 px-2 w-12/12 '>
                  <div className='social-media-card w-100 '>
                    <div className='flex flex-row w-12/12 h-100 center-div'>
                      <span className='font-secondary-italic text-white font-size-24'>
                        Follow us!
                      </span>
                      <div className='px-2 color-white'>
                        <AiOutlineTwitter size={20} />
                      </div>
                      <div className='px-2 color-white'>
                        <AiFillFacebook size={20} />
                      </div>
                      <div className='px-2 color-white'>
                        <AiFillLinkedin size={20} />
                      </div>
                      <div className='px-2 color-white'>
                        <AiOutlineInstagram size={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeAboutPage === 'mission' && (
          <div className='flex flex-col lg:flex-row '>
            <div className='lg:w-8/12 w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>
              <span className='font-primary font-size-22 text-black font-bold py-4'>
                Mission
              </span>
              <span className='font-primary font-size-16 text-black '>
                Konzortia Capital was founded on a Dream to help Startup
                companies BLASTOFF and thrust through stages of capital raising,
                fueling their launch… while improving the success of investors,
                propelling their return on investment as it rockets skyward. Our
                multicultural and multi-faceted scientists, technicians and
                entire crew team of Konzortians will build the most dynamic and
                comprehensive platforms in this galaxy, shifting paradigms with
                state-of-the-art technology, innovating and constantly evolving
                so that our partners can save time, enhance efficiencies, and
                fly at the speed of light! Together we triumph as we TAKEOFF
                with exit velocity to never look back, leading the pack… taking
                YOU above and beyond.{' '}
              </span>
              <span className='font-primary font-size-22 text-black font-bold py-4'>
                Solutions for Efficiency in Private Capital Markets
              </span>
              <span className='font-primary font-size-16 text-black '>
                The Private Capital Markets experience significant challenges
                and some inefficiencies with their operations. There is fierce
                competition and a race to secure the best deals, traditional
                networking and deal sourcing which may not necessarily identify
                all of the best investment opportunities, as well as a shortage
                of staff to plow through data rooms and extensive due diligence
                processes. Konzortia’s AI / ML monitors and analyzes millions of
                data points while scoring projects to match investors’ criteria
                with the companies that are best suited according to historical
                investment behavior. The objective is to enhance efficiency,
                gain speed to market, improve profitability and upside
                potential, and achieve success for both funders and founders.
                Our in-house Distributed Ledger System will ensure transactions
                are transparent, secure, and clear in real-time. Konzortia Hub is
                one of the first mainstream platforms to provide transactions
                for funding and exit strategies.
              </span>
              <img src='/konzortiaBuildings.svg' alt="konzortia" />
            </div>
            <div className='py-12 w-12/12 lg:w-4/12'>
              <div className='flex flex-col h-100'>
                <ContactUs />
                <div className='flex center-div flex-col'>
                  <div className='py-2 pt-4'>
                    <video controls width="600" height="350">
                        <source src="/kevin.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className='px-4 py-4'>
                  <div className='bg-gray rounded py-2'>
                    <div
                      className='py-2 px-12 flex flex-row pointer'
                      onClick={() => setActiveAboutPage('mission')}
                    >
                      <img className='px-2' src='/linkIcon.svg' alt="konzortia" />
                      <span className='font-primary color-primary font-bold'>
                        Mission
                      </span>
                    </div>
                    <div
                      className='py-2 px-12 flex flex-row pointer'
                      onClick={() => setActiveAboutPage('members')}
                    >
                      <img className='px-2' src='/linkIcon.svg' alt="konzortia" />
                      <span className='font-primary color-primary font-bold'>
                        Meet the Konzortians
                      </span>
                    </div>
                    <div
                      className='py-2 px-12 flex flex-row pointer'
                      onClick={() => setActiveAboutPage('investment')}
                    >
                      <img className='px-2' src='/linkIcon.svg' alt="konzortia" />
                      <span className='font-primary color-primary font-bold'>
                        Invest in Konzortia
                      </span>
                    </div>
                  </div>
                </div>
                <div className='flex  py-10 px-2 w-12/12 '>
                  <div className='social-media-card w-100 '>
                    <div className='flex flex-row w-12/12 h-100 center-div'>
                      <span className='font-secondary-italic text-white font-size-24'>
                        Follow us!
                      </span>
                      <div className='px-2 color-white'>
                        <AiOutlineTwitter size={20} />
                      </div>
                      <div className='px-2 color-white'>
                        <AiFillFacebook size={20} />
                      </div>
                      <div className='px-2 color-white'>
                        <AiFillLinkedin size={20} />
                      </div>
                      <div className='px-2 color-white'>
                        <AiOutlineInstagram size={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeAboutPage === 'members' && (
          <div className='flex flex-col lg:flex-row '>
            <div className='lg:w-8/12 
            w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>
              <img src='/membersTitle.svg' className='pb-12' alt="konzortia" />
              <div className='flex flex-col'>
                <div className='flex flex-row'>
                  <img
                    src='/walterGomezAvatar.svg' alt="founder"
                    className='py-4 pointer lg:px-4 lg:w-56 lg:h-56 h-36 w-36'
                    onClick={() =>
                      (window.open(
                        'https://br.linkedin.com/in/waltergomezcomz'))
                    }
                  />
                  <img
                    src='/donnieGomezAvatar.svg' alt="cofounder"
                    className='py-4 pointer lg:px-4 lg:w-56 lg:h-56 h-36 w-36'
                    onClick={() =>
                      (window.open(
                        'https://www.linkedin.com/in/donnie-gomez-b52492197'))
                    }
                  />
                  <img
                    src='/alRomeroAvatar.svg' alt="cfo"
                    className='py-4 lg:px-4 lg:w-56 cursor-pointer lg:h-56 h-36 w-36'
                    onClick={() =>
                      (window.open(
                        'https://www.linkedin.com/in/tom-krutilek/'))
                    }
                  />
                </div>
                <div className='flex flex-row'>
                  <img src='/rafayAvatar.svg' className='py-4 lg:px-4 cursor-pointer lg:w-56 lg:h-56 h-36 w-36' onClick={() =>
                      (window.open(
                        'https://www.linkedin.com/in/joseph-mancinelli-fresh411/'))
                    } />
                  <img src='/aishaAvatar.svg' className='py-4 lg:px-4 pointer lg:w-56 lg:h-56 h-36 w-36' alt="cto"   onClick={() =>
                        (window.open(
                          'https://www.linkedin.com/in/aisha-el-merhebi-717b0328'))
                      }/>
                  <img
                      src='/naianeAvatar.svg' alt='dev'
                      className='py-4 lg:px-4 lg:w-56 lg:h-56 h-36 w-36'
                    />
                </div>
                <div className='flex flex-row'>
                  
                    <img
                      src='/anabelAvatar.svg' alt="designer"
                      className='py-4 pointer lg:px-4 lg:w-56 lg:h-56 h-36 w-36'
                      onClick={() =>
                        (window.open(
                          'https://www.linkedin.com/in/anabel-miquilena'))
                      }
                    />
                    <img
                    src='/nadicaAvatar.svg' alt="legal"
                    className='py-4 pointer lg:px-4 lg:w-56 lg:h-56 h-36 w-36'
                    onClick={() =>
                      (window.open(
                        'https://www.linkedin.com/in/nadicamitreska13'))
                    }
                  />
                  <img alt='legal'
                    src='/luisBrochetAvatar.svg'
                    className='py-4 lg:px-4 lg:w-56 lg:h-56 h-36 w-36'
                  />
                </div>
                <div className='flex flex-row'>
                  
                  
                </div>
              </div>
            </div>
            <div className='py-12 w-12/12 lg:w-4/12'>
              <div className='flex flex-col h-100'>
                <ContactUs />
                <div className='flex center-div flex-col'>
                  <div className='py-2 pt-4'>
                    <YoutubeEmbed embedId='NBxGz0gv9GA' />
                  </div>
                </div>
                <div className='px-4 py-4'>
                  <div className='bg-gray rounded py-2'>
                    <div
                      className='py-2 px-12 flex flex-row pointer'
                      onClick={() => setActiveAboutPage('mission')}
                    >
                      <img className='px-2' src='/linkIcon.svg' alt='konzortia' />
                      <span className='font-primary color-primary font-bold'>
                        Mission
                      </span>
                    </div>
                    <div
                      className='py-2 px-12 flex flex-row pointer'
                      onClick={() => setActiveAboutPage('members')}
                    >
                      <img className='px-2' src='/linkIcon.svg' alt='konzortia' />
                      <span className='font-primary color-primary font-bold'>
                        Meet the Konzortians
                      </span>
                    </div>
                    <div
                      className='py-2 px-12 flex flex-row pointer'
                      onClick={() => { 
                        window.location.href=`${process.env.REACT_APP_LOCAL_URL}investment`}}
                    >
                      <img className='px-2' src='/linkIcon.svg' alt='konzortia' />
                      <span className='font-primary color-primary font-bold'>
                        Invest in Konzortia
                      </span>
                    </div>
                  </div>
                </div>
                <div className='flex  py-10 px-2 w-12/12 '>
                  <div className='social-media-card w-100 '>
                    <div className='flex flex-row w-12/12 h-100 center-div'>
                      <span className='font-secondary-italic text-white font-size-24'>
                        Follow us!
                      </span>
                      <div className='px-2 color-white'>
                        <AiOutlineTwitter size={20} />
                      </div>
                      <div className='px-2 color-white'>
                        <AiFillFacebook size={20} />
                      </div>
                      <div className='px-2 color-white'>
                        <AiFillLinkedin size={20} />
                      </div>
                      <div className='px-2 color-white'>
                        <AiOutlineInstagram size={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
   );
};
