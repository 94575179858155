import React from 'react';
import { Helmet } from 'react-helmet';

export const Tags = () => { 
  return (
    <div>
      <Helmet>
        <title>Careers at Konzortia Capital: Join the Future of Private Market Innovation</title>
        <meta name="description" content="Join Konzortia Capital and be part of the future of private market investing. Explore career opportunities in a dynamic and innovative environment shaping the next generation of investment solutions." />
      </Helmet>
    </div>
  );
};
