import React from 'react';
import { Helmet } from 'react-helmet';

export const Tags = () => { 
  return (
    <div>
      <Helmet>
        <title>Angel Investor Opportunities with Konzortia Capital: Fueling Innovation and Early-Stage Growth</title>
        <meta name="description" content="Konzortia Capital connects angel investors with innovative early-stage companies. Explore exclusive investment opportunities, advanced deal sourcing, and tools for maximizing growth potential." />
      </Helmet>
    </div>
  );
};
