import React from "react";
import {Tags} from "./AboutTags";
import Header from "../../components/Header";
import { Description } from "./Description";
import { Newsletter } from "../Home/Newsletter";
import { Footer } from "../../components/Footer";

export const About = () => {
  return <>
  <Tags />
  <Header page="about"/>

  <Description/>
  <Newsletter/>
  <Footer/>

  </>
};
