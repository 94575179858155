import React from "react";
import Header from "../../components/Header"
import { Footer } from "../../components/Footer";
import { Description } from "./Description";

export const Privacy = () => {
  return <>
  <Header page="home"/>
  <Description/>
  <Footer/>

  </>
};
