import React, { useEffect, useMemo, useState } from 'react';
import ItemsContainer from './ItemsContainer';
import SocialIcons from './SocialIcons';
import {BsInstagram} from 'react-icons/bs'
import {FaFacebookSquare} from 'react-icons/fa'
import {FaTwitterSquare} from 'react-icons/fa'
import {ImLinkedin} from 'react-icons/im'
export const Footer = () => {



    return (<>
 <footer className=" footer pb-4 text-white mt-2">
      <div className="justify-between items-center sm:px-12 px-4   py-4">
        <div>
        </div>
      </div>
      <ItemsContainer />
    </footer>   
    </>
    )
}

export const FooterSocialMedia = () => {



    return (<>
      <div className='white-line-footer'></div>
 <footer className=" footer  text-white mt-2">
      
    <div className="flex flex-row center-div ">
      <div className=" hover:text-orange-400 mr-2 cursor-pointer font-noah-black-700" >
          <a href="https://www.facebook.com/konzortia"><FaFacebookSquare size={50}/></a>
        </div>
        <div  className=" hover:text-orange-400 mr-2 cursor-pointer font-noah-black-700" >
          <a href="https://www.instagram.com/konzortia_capital"><BsInstagram size={50}/></a>
        </div>
        <div   className=" hover:text-orange-400 mr-2 cursor-pointer font-noah-black-700" >
          <a href="https://x.com/konzortia_c"><FaTwitterSquare size={50}/></a>
        </div>
        <div  className=" hover:text-orange-400 cursor-pointer font-noah-black-700" >
          <a href="https://www.linkedin.com/company/konzortiacapital/"><ImLinkedin size={50}/></a>
        </div>
    </div>
    </footer>   
    </>
    )
}