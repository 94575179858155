import React from 'react';
import { Helmet } from 'react-helmet';

export const Tags = () => { 
  return (
    <div>
      <Helmet>
        <title>About Konzortia Capital: Shaping the Future of Private Capital Markets</title>
        <meta name="description" content="Learn about Konzortia Capital, a visionary leader in private capital markets, dedicated to providing cutting-edge solutions for deal sourcing, investment management, and capital raising." />
      </Helmet>
    </div>
  );
};

