import React from 'react';

export const HeroSection = () => {

  return (
    <>
      <div
        id='header'
        className='relative w-12/12 flex flex-row w-full'
      >
       <img src='/contactHero.svg' style={{width:'100%', minHeight: '200px'}}  alt='konzortia' />
      </div>
      {/* <PopupBanner /> */}

    </>
  );
};
